import { Controller } from '@hotwired/stimulus';

const Progress = require('../../js/BindHQ/Utils/Progress');

export default class extends Controller {
    #closeOnConfirm = false;

    static targets = [
        'dialog',
        'innerDialog',
        'title',
        'body',
        'cancelButton',
        'confirmButton',
    ];

    connect() {
        // Sets a listener so that the modal can be closed when the backdrop is clicked
        this.dialogTarget.addEventListener('click', (e) => {
            if (this.innerDialogTarget.contains(e.target)) {
                return;
            }

            this.backdropClick(e);
        });

        this.#init();
    }

    openDialog({
        detail: {
            title,
            body,
            confirm,
            confirmType,
            cancel,
            onClose,
            onCancel,
            onConfirm,
            lockOnConfirm,
            hideConfirmButton,
            closeOnConfirm,
        },
    }) {
        // Register callback for confirm button
        this.onConfirm = onConfirm;

        // Register callback for cancel button
        this.onClose = onClose;

        this.onCancel = onCancel;

        this.lockOnConfirm = lockOnConfirm;

        this.#closeOnConfirm = closeOnConfirm;

        // Set relevant text on targets
        this.titleTarget.innerHTML = title;
        this.bodyTarget.innerHTML = body;

        this.confirmButtonTarget.disabled = false;
        this.confirmButtonTarget.innerHTML = confirm;

        this.cancelButtonTarget.disabled = false;
        this.cancelButtonTarget.innerHTML = cancel || 'Cancel';

        // Set class names for confirm button
        const buttonType = confirmType || 'primary';
        this.confirmButtonTarget.className = `btn btn-${buttonType}`;

        // Hide confirm button if no confirm text is set
        this.confirmButtonTarget.classList.add(
            confirm ? 'd-inline-block' : 'd-none',
        );

        // Set class names for cancel button to make it primary if no confirm button displayed
        const cancelType = confirm ? 'secondary' : 'primary';
        this.cancelButtonTarget.className = `btn btn-${cancelType}`;

        if (hideConfirmButton) {
            this.confirmButtonTarget.classList.add('d-none');
        }

        // Open modal and set body to overflow hidden to prevent scroll
        document.body.classList.add('modal-open');
        this.dialogTarget.showModal();

        // Prevent autofocus of first button (always cancel)
        this.cancelButtonTarget.blur();
    }

    confirmDialog() {
        if (null === this.onConfirm) {
            throw new Error('No confirm callback set for dialog.');
        }

        this.confirmClicked = true;

        if (this.lockOnConfirm) {
            this.confirmButtonTarget.disabled = true;
            this.cancelButtonTarget.disabled = true;

            Progress.start();
        }

        this.onConfirm();

        if (typeof this.#closeOnConfirm === 'boolean' && this.#closeOnConfirm) {
            this.closeDialog(new Event('click'));
        }
    }

    cancelDialog(event) {
        if (typeof this.onCancel === 'function') {
            this.onCancel();
        }
        this.closeDialog(event);
    }

    closeDialog(event) {
        if (typeof this.onClose === 'function') {
            this.onClose();
        }

        event.preventDefault();

        this.dialogTarget.close();
        document.body.classList.remove('modal-open');

        this.#init();
    }

    backdropClick(event) {
        if (this.confirmClicked && this.lockOnConfirm) {
            event.preventDefault();
            event.stopPropagation();

            return;
        }

        event.target === this.dialogTarget && this.dialogTarget.close(event);
        document.body.classList.remove('modal-open');

        this.#init();
    }

    #init() {
        this.onConfirm = null;
        this.onCancel = null;
        this.confirmClicked = false;
        this.lockOnConfirm = false;
    }
}
