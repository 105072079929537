import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static values = {
        number: String,
    };

    openModal() {
        window.dispatchEvent(
            new CustomEvent('confirm--button:clicked', {
                detail: {
                    title: 'CSV cannot be downloaded',
                    body: `The results contain ${this.numberValue} applications, BindHQ only allows 5,000 at a time to be downloaded by CSV. Try adding some more filters so you get fewer results and try again.`,
                    confirm: 'Cancel',
                    cancel: 'Add Filters',
                    onCancel: () => {
                        const wrapper = document.querySelector(
                            '.table-listing-wrapper',
                        );

                        wrapper.classList.add('table-listing-tools-on');
                    },
                    onConfirm: () => {
                        window.dispatchEvent(
                            new CustomEvent('confirm--dialog:closeDialog'),
                        );
                    },
                },
            }),
        );
    }
}
