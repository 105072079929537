import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = [
        'lobArchiveCheckbox',
        'saveArchivedChangesButton',
        'saveActiveChangesButtons',
    ];

    connect() {
        this.archiveStateChanged();
    }

    archiveStateChanged() {
        if (this.lobArchiveCheckboxTarget.checked) {
            this.saveArchivedChangesButtonTarget.classList.remove('d-none');
            this.saveActiveChangesButtonsTarget.classList.add('d-none');
        } else {
            this.saveActiveChangesButtonsTarget.classList.remove('d-none');
            this.saveArchivedChangesButtonTarget.classList.add('d-none');
        }
    }
}
