import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['optionCode', 'limit', 'limitColumn'];

    static values = {
        charged: String,
    };

    connect() {
        this.#onChange();
    }

    onOptionChanged(event) {
        this.#onChange();
    }

    #onChange() {
        if (this.optionCodeTarget.value === this.chargedValue) {
            this.limitColumnTarget
                .querySelector('label')
                .classList.add('required');
            this.limitTarget.removeAttribute('disabled');
        } else {
            this.limitTarget.value = null;
            this.limitColumnTarget
                .querySelector('label')
                .classList.remove('required');
            this.limitTarget.setAttribute('disabled', 'disabled');
        }
    }
}
