import { Controller } from '@hotwired/stimulus';

const { CollectionIndexer } = require('../../js/BindHQ/Form/CollectionIndexer');

const {
    LineItemRegenerator,
    LineItemRegeneratorEvents,
} = require('../../js/BindHQ/Form/LineItemRegenerator');

const {
    FormFlowReloader,
    FormFlowReloaderEvents,
} = require('../../js/BindHQ/Form/FormFlowReloader');

const {
    TriaToggler,
    TriaTogglerEvents,
} = require('../../js/BindHQ/Quote/TriaToggler');

const {
    ManualTaxesToggler,
    ManualTaxesTogglerEvents,
} = require('../../js/BindHQ/Quote/ManualTaxesToggler');

const {
    BillingTypeListener,
    BillingTypeListenerEvents,
} = require('../../js/BindHQ/Quote/BillingTypeListener');

const {
    InstallmentPlanToggler,
    InstallmentPlanTogglerEvents,
} = require('../../js/BindHQ/Quote/InstallmentPlanToggler');

export default class extends Controller {
    static values = {
        premiumLineItemType: String,
        mgaFeeLineItemType: String,
        carrierFeeLineItemType: String,
        directBillBillingType: String,
        directBillMgaFeeEnabled: Boolean,
    };

    static targets = ['financed', 'installmentPlanApplied'];

    #formFlowReloader;

    connect() {
        const container = $(this.element);

        this.#formFlowReloader = new FormFlowReloader(container);

        const lineItemRegenerator = new LineItemRegenerator(container);
        const triaToggler = new TriaToggler(container);
        const collectionIndexer = new CollectionIndexer();
        const manualTaxesToggler = new ManualTaxesToggler(
            container,
            collectionIndexer,
        );
        const installmentPlanToggler = new InstallmentPlanToggler(container);

        const billingTypeListener = new BillingTypeListener(
            container,
            this.premiumLineItemTypeValue,
            this.mgaFeeLineItemTypeValue,
            this.carrierFeeLineItemTypeValue,
            this.directBillBillingTypeValue,
            this.directBillMgaFeeEnabledValue,
        );

        lineItemRegenerator.subscribe(LineItemRegeneratorEvents.CHANGED, () =>
            this.#formFlowReloader.reload(),
        );
        lineItemRegenerator.subscribe(LineItemRegeneratorEvents.KEYUP, () =>
            this.#formFlowReloader.reload(),
        );
        triaToggler.subscribe(TriaTogglerEvents.CHANGED, () =>
            this.#formFlowReloader.reloadNow(),
        );
        triaToggler.subscribe(TriaTogglerEvents.KEYUP, () =>
            this.#formFlowReloader.reload(),
        );
        manualTaxesToggler.subscribe(ManualTaxesTogglerEvents.CHANGED, () =>
            this.#formFlowReloader.reloadNow(),
        );
        billingTypeListener.subscribe(BillingTypeListenerEvents.CHANGED, () =>
            this.#formFlowReloader.reloadNow(),
        );
        installmentPlanToggler.subscribe(
            InstallmentPlanTogglerEvents.CHANGED,
            () => this.#formFlowReloader.reloadNow(),
        );

        this.#formFlowReloader.subscribe(
            FormFlowReloaderEvents.CHANGED,
            (xhr) => {
                lineItemRegenerator.render(xhr);
                triaToggler.render(xhr);
                installmentPlanToggler.render(xhr);

                this.maybeDisablePremiumFinancing();
                this.maybeDisableInstallmentPlan();
            },
        );

        lineItemRegenerator.init();
        triaToggler.init();
        manualTaxesToggler.init();
        billingTypeListener.init();
        installmentPlanToggler.init();

        this.maybeDisableInstallmentPlan();
        this.maybeDisablePremiumFinancing();
    }

    onPremiumFinanceChange(event) {
        this.#formFlowReloader.reload();
    }

    maybeDisableInstallmentPlan() {
        if (!this.hasInstallmentPlanAppliedTarget) {
            return;
        }

        if (!this.financedTarget.checked) {
            this.installmentPlanAppliedTarget.disabled = false;

            return;
        }

        this.installmentPlanAppliedTarget.disabled = true;
    }

    maybeDisablePremiumFinancing() {
        if (!this.hasInstallmentPlanAppliedTarget) {
            return;
        }

        if (!this.installmentPlanAppliedTarget.checked) {
            this.financedTarget.disabled = false;

            return;
        }

        this.financedTarget.disabled = true;
    }
}
