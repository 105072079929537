const InstallmentPlanTogglerEvents = {
    CHANGED: 'bindhq.quote.installment_plan_toggler.changed',
};

const Observable = require('../Observable');

class InstallmentPlanToggler extends Observable {
    /**
     * @param {jQuery} container
     */
    constructor(container) {
        super();

        this.container = container;
    }

    init() {
        this.container.on('change', '.installment-plan-applied', () =>
            super.fire(InstallmentPlanTogglerEvents.CHANGED),
        );

        this.container.on('change', '.installment-plan', () =>
            super.fire(InstallmentPlanTogglerEvents.CHANGED),
        );
    }

    render(xhr) {
        if (!bindhq.isGranted('FEATURE_INSTALLMENT_PLANS')) {
            return;
        }

        const html = $(xhr.responseText);
        const oldContainer = this.container.findOne('#installment-plans');
        const newContainer = html.findOne('#installment-plans');

        oldContainer.replaceWith(newContainer);
    }
}

module.exports = { InstallmentPlanToggler, InstallmentPlanTogglerEvents };
