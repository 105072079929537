import { Controller } from '@hotwired/stimulus';
import { CollectionEvents } from '../../js/BindHQ/Form/Collection';
const numeral = require('numeral');

export default class extends Controller {
    static targets = ['lineItemField', 'lineItemTotal'];

    connect() {
        this.onInvoiceLineItemInput();
        this.element.addEventListener('submit', this.onFormSubmit.bind(this));
        this.element.addEventListener(
            CollectionEvents.REMOVED,
            this.onInvoiceLineItemInput.bind(this),
        );
    }

    onInvoiceLineItemInput() {
        const totals = this.#getLineItemTotals();

        this.lineItemTotalTargets.forEach((totalElement) => {
            const lineItemType = totalElement.dataset.lineItemType;
            const total = totals[lineItemType] || numeral(0);
            const totalFormatted = total.format('0.000');

            totalElement.querySelector('span').innerHTML = `${totalFormatted}%`;

            if (0 !== total.difference(100)) {
                totalElement
                    .querySelector('i, svg')
                    .classList.remove('fa-check-circle', 'help-success');
                totalElement
                    .querySelector('i, svg')
                    .classList.add('fa-exclamation-circle', 'help-warning');
            } else {
                this.lineItemFieldTargets.forEach((lineItemFieldTarget) => {
                    if (
                        lineItemType !==
                        lineItemFieldTarget.dataset.lineItemType
                    ) {
                        return;
                    }

                    lineItemFieldTarget.classList.remove('error');
                });

                totalElement
                    .querySelector('i, svg')
                    .classList.remove('fa-exclamation-circle', 'help-warning');
                totalElement
                    .querySelector('i, svg')
                    .classList.add('fa-check-circle', 'help-success');
            }
        });
    }

    onFormSubmit() {
        if (
            this.element.querySelector('.collection-item') &&
            this.element.querySelector('.help-warning')
        ) {
            event.preventDefault();

            const totals = this.#getLineItemTotals();

            Object.keys(totals).forEach((lineItemType) => {
                const total = totals[lineItemType] || numeral(0);

                if (0 !== total.difference(100)) {
                    this.lineItemFieldTargets.forEach((lineItemFieldTarget) => {
                        if (
                            lineItemType !==
                            lineItemFieldTarget.dataset.lineItemType
                        ) {
                            return;
                        }

                        lineItemFieldTarget.classList.add('error');
                    });
                }
            });
        }
    }

    #getLineItemTotals() {
        const totals = {};

        this.lineItemFieldTargets.forEach((input) => {
            const lineItemType = input.dataset.lineItemType;
            const value = input.value === '' ? 0 : parseFloat(input.value);

            if (!totals[lineItemType]) {
                totals[lineItemType] = numeral(0);
            }

            totals[lineItemType].add(value);
        });

        return totals;
    }
}
