import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        this.element
            .querySelectorAll('.select2')
            .forEach(bindhq.forms.select.init);
        this.element
            .querySelectorAll('.multi-select')
            .forEach(bindhq.forms.multiselect.init);
        this.element
            .querySelectorAll('.ajax-autocomplete')
            .forEach(bindhq.forms.ajaxautocomplete.init);
    }
}
