import { Controller } from '@hotwired/stimulus';
const Progress = require('../js/BindHQ/Utils/Progress');
const Fuse = require('fuse.js');

export default class extends Controller {
    static targets = ['modal', 'input', 'results'];

    static values = {
        url: String,
    };

    static commands = [
        {
            name: 'Compose Email',
            description: 'Open email compose sidebar',
            handler: () => bindhq.mailpane.show(),
        },
    ];

    connect() {
        this.selectedIndex = 0;
        this.allCommands = [];
        this.currentCommands = [];
        this.fuse = null;
    }

    handleKeydown(event) {
        // Handle Cmd+K / Ctrl+K to open
        if ((event.metaKey || event.ctrlKey) && event.key === 'k') {
            event.preventDefault();
            event.stopPropagation();

            this.open();
        }
    }

    handleKeyup(event) {
        // Only handle other keys if modal is open
        if (!this.modalTarget.classList.contains('hidden')) {
            switch (event.key) {
                case 'Escape':
                    event.preventDefault();
                    event.stopPropagation();

                    this.close();
                    break;

                case 'ArrowDown':
                    event.preventDefault();
                    event.stopPropagation();

                    this.selectNext();
                    break;

                case 'ArrowUp':
                    event.preventDefault();
                    event.stopPropagation();

                    this.selectPrevious();
                    break;

                case 'Enter':
                    event.preventDefault();
                    event.stopPropagation();

                    this.executeSelected();
                    break;
            }
        }
    }

    async open() {
        if (null === this.fuse) {
            Progress.start();

            const response = await fetch(this.urlValue);

            this.allCommands = [
                ...(await response.json()).map((cmd) => ({
                    name: cmd.name,
                    description: cmd.description,
                    handler: () => (location.href = cmd.url),
                })),
                ...this.constructor.commands,
            ].sort((a, b) => a.name.localeCompare(b.name));

            this.fuse = new Fuse(this.allCommands, {
                keys: ['name', 'description'],
            });

            Progress.stop();
        }

        this.currentCommands = this.allCommands;
        this.selectedIndex = 0;
        this.renderResults();

        this.modalTarget.classList.remove('hidden');
        this.inputTarget.focus();
    }

    close() {
        this.modalTarget.classList.add('hidden');
        this.inputTarget.value = '';
        this.selectedIndex = 0;
        this.currentCommands = [];
    }

    async search(event) {
        const query = event.target.value.toLowerCase();

        this.currentCommands = query
            ? this.fuse.search(query).map((result) => result.item)
            : this.allCommands;
        this.selectedIndex = 0;
        this.renderResults();
    }

    renderResults() {
        if (this.currentCommands.length === 0) {
            this.resultsTarget.innerHTML = `
                <div class="p-4 text-center text-gray-500">
                    No commands found
                </div>
            `;
            return;
        }

        this.resultsTarget.innerHTML = this.currentCommands
            .map(
                (command, index) => `
<div class="command-item ${index === this.selectedIndex ? 'selected' : ''}"
     data-command-palette-target="command"
     data-action="click->command-palette#executeCommand"
     data-command-index="${index}">
    <div class="flex items-center">
        <div>
            <div class="command-name">${command.name}</div>
            <div class="command-description">${command.description || ''}</div>
        </div>
    </div>
</div>
        `,
            )
            .join('');
    }

    selectNext() {
        if (this.currentCommands.length > 0) {
            this.selectedIndex =
                (this.selectedIndex + 1) % this.currentCommands.length;
            this.renderResults();
        }
    }

    selectPrevious() {
        if (this.currentCommands.length > 0) {
            this.selectedIndex =
                (this.selectedIndex - 1 + this.currentCommands.length) %
                this.currentCommands.length;
            this.renderResults();
        }
    }

    executeCommand(event) {
        if (event && event.currentTarget) {
            const index = parseInt(event.currentTarget.dataset.commandIndex);

            if (!isNaN(index) && this.currentCommands[index]) {
                this.currentCommands[index].handler();
            }
        } else {
            this.currentCommands[this.selectedIndex].handler();
        }

        this.close();
    }

    executeSelected() {
        this.executeCommand();
    }

    backdropClick(event) {
        if (event.target === this.modalTarget) {
            this.close();
        }
    }
}
