import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    onClick(event) {
        event.preventDefault();

        const href = event.target.getAttribute('href');
        if (!href || !href.startsWith('#')) {
            return;
        }

        const targetId = href.substring(1);
        const targetElement = document.getElementById(targetId);

        const templateReplacement = targetElement.querySelector(
            '.template-replacement',
        );
        if (templateReplacement) {
            templateReplacement.classList.remove('is-collapsed');
        }

        const contentElement = targetElement.querySelector(
            '[data-show-hide-target="content"]',
        );
        if (contentElement) {
            contentElement.classList.add('show');
        }
    }
}
