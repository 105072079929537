import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    connect() {
        this.element.addEventListener('change', (e) => {
            if (1 !== e.target.files.length) {
                return;
            }

            const fileName = e.target.files[0].name;

            document.querySelector(
                '.custom-file-label[for=' + e.target.id + ']',
            ).innerHTML = fileName;
        });
    }
}
