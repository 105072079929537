import { Controller } from '@hotwired/stimulus';

export default class extends Controller {
    static targets = ['content', 'showMore', 'showLess'];

    connect() {
        this.content = this.contentTarget.innerHTML;

        if ('true' === this.element.closest('body').dataset.taxNotesExpanded) {
            this.#expand();
        } else {
            this.#collapse();
        }
    }

    onShowMoreClick(event) {
        event.preventDefault();

        this.#expand();
    }

    onShowLessClick(event) {
        event.preventDefault();

        this.#collapse();
    }

    #expand() {
        this.#setExpanded('true');

        this.contentTarget.innerHTML = this.content;
        this.showMoreTarget.classList.add('d-none');
        this.showLessTarget.classList.remove('d-none');
        this.showLessTarget.classList.add('d-block');
    }

    #collapse() {
        this.#setExpanded('false');

        this.contentTarget.innerHTML = this.content.substring(0, 100) + '...';
        this.showMoreTarget.classList.remove('d-none');
        this.showLessTarget.classList.add('d-none');
        this.showLessTarget.classList.remove('d-block');
    }

    #setExpanded(value) {
        this.element.closest('body').dataset.taxNotesExpanded = value;
    }
}
