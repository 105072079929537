import { Controller } from '@hotwired/stimulus';
import TomSelect from 'tom-select';
import Popper from 'popper.js';

const { TomSelectEvents } = require('../js/BindHQ/Form/TomSelect');

export default class extends Controller {
    static values = {
        allowReinitialize: {
            type: Boolean,
            default: true,
        },
    };

    connect() {
        if (!this.element.tomselect || this.allowReinitializeValue) {
            this.#createAutocomplete();
        }
    }

    #createAutocomplete() {
        const dropdownCssClass = this.element.dataset.dropdownCssClass ?? null;
        const popperPlacement =
            this.element.dataset.popperPlacement ?? 'bottom';

        const render = (data, escape) => {
            return data.description
                ? '<div class="has-description">' +
                      escape(data.text) +
                      '<span class="description">' +
                      escape(data.description) +
                      '</span>' +
                      '</div>'
                : `<div>${escape(data.text)}</div>`;
        };

        const config = this.#mergeObjects(this.#getCommonConfig(), {
            maxOptions: null,
            onChange: TomSelectEvents.onChangeValidate,
            dropdownParent: 'body',
            onInitialize: function () {
                this.popper = new Popper(this.control, this.dropdown, {
                    placement: popperPlacement,
                    modifiers: {
                        preventOverflow: {
                            escapeWithReference: true,
                        },
                    },
                    positionFixed: true,
                });
            },
            onDropdownOpen: function () {
                this.popper.update();

                const isModalOpen =
                    document.body.classList.contains('modal-open');
                this.dropdown.classList.toggle('modal-dropdown', isModalOpen);
            },
            render: {
                item: render,
                option: render,
                dropdown: function () {
                    return dropdownCssClass
                        ? `<div class="${dropdownCssClass}"></div>`
                        : '<div></div>';
                },
            },
            searchField: ['text', 'description'],
        });

        if (this.element.multiple) {
            config.onItemAdd = function () {
                this.setTextboxValue('');
                this.refreshOptions();
                this.popper.update();
            };
            config.onItemRemove = function () {
                this.popper.update();
            };
        }

        if (this.element.dataset.autocompleteNoInput) {
            config.plugins = {};
            config.controlInput = null;
        }

        return new TomSelect(this.element, config);
    }

    #getCommonConfig() {
        const config = {
            selectOnTab: true,
            plugins: {
                no_active_items: {},
                dropdown_input: {},
            },
        };

        if (
            null === this.element.getAttribute('required') &&
            null === this.element.getAttribute('disabled')
        ) {
            config.plugins.clear_button = { title: '' };
        }

        if (null !== this.element.getAttribute('multiple')) {
            config.plugins.remove_button = { title: '' };
            config.selectOnTab = false;
        }

        return config;
    }

    #mergeObjects(object1, object2) {
        return { ...object1, ...object2 };
    }
}
