import { Controller } from '@hotwired/stimulus';
import Popper from 'popper.js';

export default class extends Controller {
    initialize() {
        this._onPreConnect = this._onPreConnect.bind(this);
    }

    connect() {
        this.element.addEventListener(
            'autocomplete:pre-connect',
            this._onPreConnect,
        );
    }

    disconnect() {
        this.element.removeEventListener(
            'autocomplete:pre-connect',
            this._onPreConnect,
        );
    }

    _onPreConnect(event) {
        const popperPlacement =
            this.element.dataset.popperPlacement ?? 'bottom-start';

        const options = event.detail.options;

        options.dropdownParent = 'body';

        const plugins = [];

        if (this.element.hasAttribute('multiple')) {
            plugins.push('remove_button');
        }

        if (
            !this.element.hasAttribute('required') &&
            !this.element.hasAttribute('multiple')
        ) {
            plugins.push('clear_button');
        }

        options.plugins = plugins;

        const dropdownCssClass = this.element.dataset.dropdownCssClass ?? null;

        options.render.dropdown = function () {
            return dropdownCssClass
                ? `<div class="${dropdownCssClass}"></div>`
                : '<div></div>';
        };

        options.onInitialize = function () {
            this.popper = new Popper(this.control, this.dropdown, {
                placement: popperPlacement,
                modifiers: {
                    preventOverflow: {
                        escapeWithReference: true,
                    },
                },
                positionFixed: true,
            });
        };

        options.onDropdownOpen = function () {
            this.popper.update();

            const isModalOpen = document.body.classList.contains('modal-open');
            this.dropdown.classList.toggle('modal-dropdown', isModalOpen);
        };
    }
}
